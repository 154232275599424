import React from "react";
import * as styles from "../../styles/index.module.scss";
import { ProductRef } from "./productRef";
import BlockContent from "@sanity/block-content-to-react";
import { useInView } from "react-intersection-observer";

interface Props {
  data: any;
  raw: string;
  lang: string;
}

export const ProductStars: React.FC<Props> = ({ data, raw, lang }) => {
  const { ref, inView, entry } = useInView({
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  });

  return (
    <div className="backgroundFull">
      <div className={`indexMargin ${styles.indexProductWrapper}`}>
        <div className={styles.mobileTitle}>
          <div className={styles.title}>
            <BlockContent blocks={data.home.title[raw]} />
          </div>
          <div className={styles.text}>
            <BlockContent blocks={data.home.description[raw]} />
          </div>
        </div>
        <div ref={ref} className={`${styles.starContainer} flexRow maxWidth `}>
          {data.star.productsList.length &&
            data.star.productsList.map((star: any, index: number) => (
              <div className={`${styles.fifty} ${!inView && "starAnim"}`} key={`star-${index}`}>
                <ProductRef
                  url={star.sizes[0].localeImage.image.asset.url}
                  alt={star.sizes[0].localeImage[lang].alt}
                  title={`XORUS ${star.title}`}
                  description={star.description[raw]}
                  slug={star.slug}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
