// extracted by mini-css-extract-plugin
export const starContainer = "index-module--starContainer--LN0WG";
export const pageTitle = "index-module--pageTitle--3EpnQ";
export const title = "index-module--title--FOKTQ";
export const text = "index-module--text--39IJG";
export const fifty = "index-module--fifty--21mjv";
export const socials = "index-module--socials--2cVaz";
export const pageWrap = "index-module--pageWrap--18iJK";
export const welcomeWrap = "index-module--welcomeWrap--fwWVy";
export const secondImageTextWrapper = "index-module--secondImageTextWrapper--3xt2g";
export const mobileTitle = "index-module--mobileTitle--371aU";
export const firstImage = "index-module--firstImage--2UZCK";
export const secondImage = "index-module--secondImage--2Fy3x";
export const indexProductWrapper = "index-module--indexProductWrapper--Fn3oF";
export const absoluteWrapper = "index-module--absoluteWrapper--2wLyM";
export const visible = "index-module--visible--3W_NI";
export const zoomOnScroll = "index-module--zoomOnScroll--2_5IU";
export const scale = "index-module--scale--2624I";
export const parallax = "index-module--parallax--1YYhl";
export const translate = "index-module--translate--2zpDR";
export const fadeImg = "index-module--fadeImg--19Wq5";
export const FadeInOut = "index-module--FadeInOut--1qHYL";
export const filter = "index-module--filter--3cUf4";
export const dissapear = "index-module--dissapear--1cAFS";