import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import { usePageContext } from "./Context/pageContext";
import * as styles from "../styles/about.module.scss";

export const About = () => {
  const { about } = useStaticQuery(query);
  const { lang } = usePageContext();
  const raw = "_raw" + lang.charAt(0).toUpperCase() + lang.slice(1);

  return (
    <div id="about" className="backgroundFull topMargin">
      <div
        className="indexMargin"
        style={{
          backgroundColor: "#202023",
          zIndex: 1,
          marginTop: "-1px",
          position: "relative",
        }}
      >
        <div className="maxWidth">
          <AboutWrapper>
            <AboutTitle>{about.title[lang]}</AboutTitle>
            <AboutContent>
              <BlockContent blocks={about.body[raw]} />
            </AboutContent>
            {/* <iframe
                // width="100%"
                // height="100%"
                width="598"
                height="336"
                src="https://www.youtube.com/embed/biYdUZXfz9I"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="about"
                className={styles.iframe}
                // style={{
                //   margin: "0 auto 140px",
                //   marginBottom: "140px",
                //   filter: "drop-shadow(rgba(0,0,0,0.5) 0px 20px 40px",
                // }}
              ></iframe> */}
          </AboutWrapper>
        </div>
      </div>
    </div>
  );
};

const AboutWrapper = styled.div`
  background-color: #202023;
  z-index: 1;
  position: relative;
  /* width: 1000px; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 140px;
  @media only screen and (max-width: 1023px) {
    padding-bottom: 0px;
  }
`;
const AboutTitle = styled.p`
  font-family: Spectral;
  font-style: normal;
  font-weight: 200;
  font-size: 48px;
  line-height: 110.2%;
  text-align: center;
  text-transform: uppercase;
  color: #eedd24;
  margin-bottom: 35px;

  @media only screen and (max-width: 1023px) {
    text-align: left;
    font-size: 36px;
  }
`;

const AboutContent = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-bottom: 50px;
  @media only screen and (max-width: 1023px) {
    text-align: left;
  }
`;

const query = graphql`
  query {
    about: sanityAbout {
      body {
        _rawEn
        _rawEs
      }
      title {
        en
        es
      }
    }
  }
`;
