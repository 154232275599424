import { graphql, Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import Image from "../components/image";
import Layout from "../components/layout";
import SEO from "../components/seo";
// import PecheurEauDouce from "../../static/pecheur_mer_v3.png";
// import PecheurEauMer from "../../static/pecheur_lac_v3.png";
// import PoissonDoux from "../../static/PoissonDoux.png";
// import PoissonMer from "../../static/PoissonMer.png";
import styled from "styled-components";
import * as styles from "../styles/index.module.scss";
import { About } from "../components/about";
import { ProductRef } from "../components/Product/productRef";
import { usePageContext } from "../components/Context/pageContext";
import BlockContent from "@sanity/block-content-to-react";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import { useIntersection } from "react-use";
import { MdFormatIndentIncrease } from "react-icons/md";
import { SecondImage } from "../components/Home/secondImage";
import { ProductStars } from "../components/Product/productStars";
import { Background } from "react-parallax";
import { useWindowSize } from "../components/Utils/useWindowSize";
import { useInView } from "react-intersection-observer";

interface Props {
  data: any;
}

const IndexPage: React.FC<Props> = ({ data }) => {
  const [index, setIndex] = useState(0);
  const { lang } = usePageContext();
  const raw = "_raw" + lang.charAt(0).toUpperCase() + lang.slice(1);
  const [fishRect, setFishRect] = useState<any>(null);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const secondImageRef = useRef(null);
  const dimensions = useWindowSize();

  useEffect(() => {
    let interval: any;
    if (data.home.backgroundPictures.length > 1) {
      interval = setInterval(() => {
        setIndex((index) => {
          if (index < data.home.backgroundPictures.length - 1) return index + 1;
          else return 0;
        });
      }, 2500);
  }
    // window.removeEventListener("scroll", () => {}, false) &&
    fishRect &&
      window.addEventListener(
        "scroll",
        () => {
          const offset =
            (window.pageYOffset + window.innerHeight - fishRect.top) /
            (fishRect.top + 1.4 * fishRect.height - window.innerHeight);
          const limit = offset < 0 ? 0 : offset >= 1 ? 0.9999 : offset;
          if (interval && limit > 0.4) clearInterval(interval);
          document.body.style.setProperty("--scroll", `${limit}`);
        },
        false
      );
    return () => {
      window.removeEventListener("scroll", () => {}, false);
      if (data.home.backgroundPictures.length > 1) {
        clearInterval(interval);
      }
    };
    // }, []);
  }, [fishRect]);

  // transition: transform 0.1s;
  // transform: scale(calc(var(--scroll) * 0.2 + 1));
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIndex((index) => {
  //       if (index < data.home.backgroundPictures.length - 1) return index + 1;
  //       else return 0;
  //     });
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, []);

  const { ref, inView, entry } = useInView({
    root: null,
    rootMargin: "0px",
    threshold: 0.4,
  });

  return (
    <>
      <Layout theme="light">
        <SEO
          title={data?.seo?.home?.title && data?.seo?.home?.title[lang]}
          description={data?.seo?.home?.desc && data?.seo?.home?.desc[lang]}
          image={data?.seo?.home?.image?.asset?.url}
        />
        <div className={styles.pageWrap}>
          {/* <div style={{ position: "relative", backgroundImage: trameLight }}> */}
          <div ref={ref} className={`${styles.parallax}`}>
            <div className={styles.filter}/>
            {/* <div className={`${styles.parallax} ${styles.fadeImg}`}> */}
            {data.home.backgroundPictures.map((image: any, i: number) => (
              <Img
                fluid={image.fishermanPicture.image.asset.fluid}
                style={{ position: i !== 0 && "absolute" }}
                className={`${styles.firstImage} ${
                  i === index ? styles.visible : ""
                }`}
                key={`i-${i}`}
              />
            ))}
            {/* <Img
              fluid={
                data?.home.backgroundPictures[index].fishermanPicture.image
                  .asset.fluid
              }
              className={styles.firstImage}
              // style={{ height: dimensions.width > 899 ? "100vh" : "50vh" }}
            /> */}
            {/* <img
              src={
                data.home.backgroundPictures[index].fishermanPicture.image.asset
                  .url
              }
            /> */}
          </div>
          <div className={styles.absoluteWrapper}>
            <div
              className={`margin ${styles.welcomeWrap} ${inView && "visible"}`}
            >
              <div className={`${styles.pageTitle}`}>
                <BlockContent blocks={data.home.welcomeMessage[raw]} />
              </div>
              <div className={styles.socials}>
                {data.socials.edges.map((edge: any, index: number) => (
                  <div style={{ marginBottom: "24px" }} key={`social-${index}`}>
                    <a
                      href={edge.node.url}
                      target="_blank"
                      rel="noopener"
                      // style={{ marginBottom: "24px" }}
                    >
                      <img
                        src={edge.node.logos.light.asset.url}
                        alt={edge.node.title}
                        style={{ marginBottom: 0 }}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* <div ref={secondImageRef} className={styles.secondImage}>
          <Img
            fluid={
              data?.home.backgroundPictures[index].fishPicture.image.asset.fluid
            }
          />
          <div
            className="indexMargin"
            style={{
              // zIndex: 1,
              position: "absolute",
              top: "19vw",
              left: 0,
              right: 0,
              margin: "0 auto",
            }}
          >
            <div className={styles.secondImageTextWrapper}>
              <div className={styles.title}>
                <BlockContent blocks={data.home.title[raw]} />
              </div>
              <div className={styles.text}>
                <BlockContent blocks={data.home.description[raw]} />
              </div>
            </div>
          </div>
        </div> */}
        <SecondImage
          // image={
          //   data?.home.backgroundPictures[index].fishPicture.image.asset.fluid
          images={data?.home.backgroundPictures}
          title={data.home.title[raw]}
          description={data.home.description[raw]}
          setFishRect={setFishRect}
          index={index}
        />

        <ProductStars data={data} raw={raw} lang={lang} />
        {/* 
        <div className="backgroundFull">
          <div className="indexMargin">
            <div className={styles.mobileTitle}>
              <div className={styles.title}>
                <BlockContent blocks={data.home.title[raw]} />
              </div>
              <div className={styles.text}>
                <BlockContent blocks={data.home.description[raw]} />
              </div>
            </div>
            <div className={`${styles.starContainer} flexRow maxWidth`}>
              {data.star.productsList.length &&
                data.star.productsList.map((star: any, index: number) => (
                  <div className={`${styles.fifty} ${styles.starAnim}`}>
                    <ProductRef
                      image={star.sizes[0].localeImage.image.asset.fluid}
                      alt={star.sizes[0].localeImage[lang].alt}
                      title={`XORUS ${star.title}`}
                      description={star.description[raw]}
                      slug={star.slug}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div> */}

        <About />
      </Layout>
    </>
  );
};

export default IndexPage;

const FirstImage = styled.div`
  width: 100%;
  /* background: url("pecheur_mer_v3.png") no-repeat center center fixed; */

  background-position: top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: contain;
  background-attachment: scroll;
`;

// const SecondImage = styled.div`
//   width: 100%;
//   /* margin-top: -230px; */
//   margin-top: -16%;
//   /* background: url("/PoissonDoux.png") no-repeat center center fixed; */
//   background-position: center;
//   -webkit-background-size: cover;
//   -moz-background-size: cover;
//   -o-background-size: cover;
//   background-size: contain;
//   background-attachment: scroll;
//   position: relative;
//   z-index: 1;
// `;
// filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src=url(${PecheurEauDouce}), sizingMethod='scale');
// -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src=url(${PecheurEauDouce}), sizingMethod='scale')";

const HomeTitle = styled.div`
  font-family: Spectral;
  font-style: italic;
  font-weight: normal;
  font-size: 96px;
  line-height: 90px;
  color: #eedd24;
  width: fit-content;
`;
const DescriptionTitle = styled.div`
  font-family: Spectral;
  font-style: normal;
  font-weight: 200;
  font-size: 48px;
  line-height: 110.2%;
  text-transform: uppercase;
  color: #eedd24;
  margin-bottom: 20px;
`;

const DescriptionContent = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 200%;
  letter-spacing: 0.05em;
  color: #ffffff;
`;

const SocialLogo = styled.img`
  display: flex;
`;

export const query = graphql`
  {
    star: sanityStarProducts {
      productsList {
        slug
        title
        sizes {
          localeImage {
            en {
              alt
            }
            es {
              alt
            }
            image {
              asset {
                # fixed(width: 439, height: 202) {
                #   ...GatsbySanityImageFixed

                url
                fluid(maxWidth: 1200, maxHeight: 1000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        description {
          _rawEn
          _rawEs
        }
      }
    }
    home: sanityWelcome {
      title {
        _rawEn
        _rawEs
      }
      welcomeMessage {
        _rawEn
        _rawEs
      }
      description {
        _rawEn
        _rawEs
      }
      backgroundPictures {
        fishPicture {
          en {
            alt
          }
          es {
            alt
          }
          image {
            asset {
              url
              fluid(maxWidth: 1600) {
                ...GatsbySanityImageFluid
                # fixed(width: 1600, height: 900) {
                #   ...GatsbySanityImageFixed
              }
            }
          }
        }
        fishermanPicture {
          en {
            alt
          }
          es {
            alt
          }
          image {
            asset {
              url
              fluid(maxWidth: 1600, maxHeight: 900) {
                ...GatsbySanityImageFluid
                # fixed(width: 1600, height: 900) {
                #   ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
    socials: allSanitySocialNetwork {
      edges {
        node {
          logos {
            dark {
              asset {
                url
              }
            }
            light {
              asset {
                url
              }
            }
          }
          title
          url
        }
      }
    }
    seo: sanitySiteSettings {
      home {
        desc: seoDescription {
          en
          es
        }
        image: seoImage {
          asset {
            url
          }
        }
        title: seoTitle {
          en
          es
        }
      }
    }
  }
`;
