import React, { useEffect, useRef } from "react";
import * as styles from "../../styles/index.module.scss";
import Img from "gatsby-image";
import BlockContent from "@sanity/block-content-to-react";
import { useInView } from "react-intersection-observer";
import { useWindowSize } from "../Utils/useWindowSize";

interface Props {
  images: any;
  title: any;
  description: any;
  setFishRect: any;
  index: number;
}

export const SecondImage: React.FC<Props> = ({
  images,
  title,
  description,
  setFishRect,
  index,
}) => {
  //   const ref = useRef(null);
  const reff = useRef(null);

  const dimensions = useWindowSize();
  useEffect(() => {
    if (reff.current) {
      setFishRect(reff?.current?.getBoundingClientRect());
    }
  }, [dimensions]);

  const { ref, inView, entry } = useInView({
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });

  return (
    <div ref={ref} className={styles.secondImage}>
      <div ref={reff}>
        {/* className={styles.fadeImg}> */}
        {images.map((image: any, i: number) => (
          <Img
            fluid={image.fishPicture.image.asset.fluid}
            style={{ position: i !== 0 && "absolute" }}
            className={`${styles.zoomOnScroll} ${i === index ? "visible" : ""}`}
          />
        ))}

        {/* <Img
          fluid={images[0].fishPicture.image.asset.fluid}
          className={styles.zoomOnScroll}
        /> */}
      </div>
      <div
        className="indexMargin"
        style={{
          // zIndex: 1,
          position: "absolute",
          top: "19vw",
          left: 0,
          right: 0,
          margin: "0 auto",
        }}
      >
        {/* <p>{`Header inside viewport ${inView}.`}</p> */}
        <div
          className={`${styles.secondImageTextWrapper} ${
            inView && styles.visible
          }`}
        >
          <div className={styles.title}>
            <BlockContent blocks={title} />
          </div>
          <div className={styles.text}>
            <BlockContent blocks={description} />
          </div>
        </div>
      </div>
    </div>
  );
};
